.slick-prev{
  left: -48px !important;
  
}
.slick-next:before, .slick-prev:before {
  color: #7dbf6d !important;
}
.slick-dots li button:before {
  color: #7dbf6d !important;
}
.slick-slide img {
  display: block;
  padding:20px;
}

h3.post-title.pt-3 {
  color: #71a964 !important;
}
#hyper{
  color:whitesmoke !important;
}
#hyper:hover{
  color:#3a3a3a !important;
}
#linkid:hover{
  color:#e2e2e2 !important;
}